import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { UrlService } from "../url/url.service";
import { LoginBody } from "../../requests/login-body";
import { map } from "rxjs/operators";
import { CommonService } from "../common/common.service";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  countryCode: any;

  constructor(
    private http: HttpClient,
    private url: UrlService,
    private comm: CommonService
  ) {
    this.getCountryCode();
  }

  getCountryCode() {
    return this.http
      .get<Response>("assets/json/countryCode.json")
      .pipe(map(response => response));
  }

  getDashboardStats(data) {
    return this.http.post(this.comm.baseUrl + "/getDashboardStats",data);
  }

  singIn(body: LoginBody) {
    return this.http.post(this.comm.baseUrl + "/panel/login", body);
  }
 
  forgotPasswordSendEmail(body) {
    return this.http.post(this.comm.baseUrlwitoutAdmin +"/forgotPassword", body);
  }

  resetPassword(body) {
    let endPoint:string;
    if(body.resetToken) {endPoint = 'forgotChangePassword'}
    if(body.verificationToken) {endPoint = 'verifyEmailLink'}
    return this.http.post(this.comm.baseUrlwitoutAdmin +"/restaurant/"+endPoint, body);
  }

  sendToken(token: string) {
    localStorage.setItem("token", token);
  }
  getToken() {
    return localStorage.getItem("token");
  }

  isLoggedIn() {
    return this.getToken() !== null;
  }

  getAllRestaurantCategories(url) {
    return this.http.get(this.comm.baseUrl + "/food/"+url);
  }

  addFoodCategory(body) {
    return this.http.post(this.comm.baseUrl + "/food/category", body);
  }

  editFoodCategory(body) {
    return this.http.put(this.comm.baseUrl + "/food/category", body);
  }

  getAllRestaurant() {
    return this.http.get(this.comm.baseUrl + "/food/restaurant");
  }

  editFoodRestaurant(body) {
    return this.http.put(this.comm.baseUrl + "/food/restaurant", body);
  }

  getResturantById(id) {
    return this.http.get(this.comm.baseUrl + "/food/restaurant/" + id);
  }

  getResturantFoodItemById(id) {
    return this.http.get(this.comm.baseUrl + "/food/itembyid/" + id);
  }

  addFoodRestaurant(body) {
    return this.http.post(this.comm.baseUrl + "/food/restaurant", body);
  }

  getAllOrders(page, id) {
    return this.http.get(
      this.comm.baseUrl + "/food/orders?&page=" + page + "&restaurantId=" + id
    );
  }

  getEventList(data){
    return this.http.get(this.comm.baseUrl+"/food/getEvents?"+ data);
  }

  getPendingOrders(status, page, id) {
    return this.http.get(
      this.comm.baseUrl + "/food/orders?status=" + status + "&page=" + page + "&restaurantId=" + id
    );
  }
  getCrm() {
    return this.http.get(this.comm.baseUrl + "/food/getCrm");
  }

  addCrm(body) {
    return this.http.post(this.comm.baseUrl + "/food/addCrm", body);
  }

  getOutletById(id) {
    return this.http.get(this.comm.baseUrl + "/food/restaurant/outlet/" + id);
  }


  addRestaurantOutlet(body) {
    return this.http.post(this.comm.baseUrl + "/food/restaurant/outlet", body);
  }

  editRestaurantOutlet(body) {
    return this.http.put(this.comm.baseUrl + "/food/restaurant/outlet", body);
  }


  getRestaurantTypeById(id) {
    return this.http.get(this.comm.baseUrl + "/food/type/" + id);
  }

  getRestaurantItemeById(id) {
    return this.http.get(this.comm.baseUrl + "/food/item/" + id);
  }

  addRestaurantFoodType(body) {
    return this.http.post(this.comm.baseUrl + "/food/type", body);
  }

  addRestaurantFoodItem(body) {
    return this.http.post(this.comm.baseUrl + "/food/item", body);
  }

  editRestaurantFoodType(body) {
    return this.http.put(this.comm.baseUrl + "/food/type", body);
  }

  editRestaurantFoodItem(body) {
    return this.http.put(this.comm.baseUrl + "/food/item", body);
  }
  getRestaurantOrderDetail(id) {
    return this.http.get(this.comm.baseUrl + "/food/order/" + id);
  }

  changePassword(body) {
    return this.http.post(this.comm.baseUrl + "/panel/changepass", body);
  }

  getAddOns(id) {
    return this.http.get(this.comm.baseUrl + '/getAddOns/' + id);
  }

  getAddOnById(id) {
    return this.http.get(this.comm.baseUrl + '/getAddOnById/'  + id);
  }

  addAddOn(body) {
    return this.http.post(this.comm.baseUrl + '/addOns', body);
  }

  deleteAddOn(id) {
    return this.http.delete(this.comm.baseUrl + '/deleteAddOns/' + id);
  }

  activeInactiveAddOn(body) {
    return this.http.put(this.comm.baseUrl + '/addOns', body);
  }

  editAddOn(body) {
    return this.http.put(this.comm.baseUrl + '/addOns', body);
  }

  getAdminModules() {
    return this.http.get(this.comm.baseUrl + "/admin/getAdminModules");
  }

  addSubAdmin(data) {
    return this.http.post(this.comm.baseUrl + "/admin/subAdmin",data);
  }
  updateSubAdmin(data) {
    return this.http.put(this.comm.baseUrl + "/admin/subAdmin",data);
  }
  getAllSubAdmin(data) {
    return this.http.get(this.comm.baseUrl + "/admin/subAdmin"+(data.id?('/'+data.id):''),data);
  }
  deleteSubAdmin(data) {
    return this.http.delete(this.comm.baseUrl + "/admin/subAdmin",data);
  }
  
  addAccessModules(data) {
    return this.http.post(this.comm.baseUrl + "/admin/accessModule",data);
  }

  editAccessModules(data) {
    return this.http.put(this.comm.baseUrl + "/admin/accessModule",data);
  }

  deleteAccessModules(param) {
    return this.http.delete(this.comm.baseUrl + "/admin/accessModule",param);
  }

  getAccessModuleId(id) {
    return this.http.get(this.comm.baseUrl + "/admin/getAccessModuleId/" +id);
  }

  getAllAccessModules() {
    return this.http.get(this.comm.baseUrl + "/admin/accessModule");
  }
}
