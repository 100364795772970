import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "src/app/commonservice/auth.guard";
import { AuthguardGuardGuard } from "./commonservice/authguard-guard.guard";
import { ForgotpasswordComponent } from "./forgotpassword/forgotpassword.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupComponent } from "./signup/signup.component";
import { VerifyComponent } from "./verify/verify.component";
const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full"
  },
  {
    path: "",
    canActivate: [AuthguardGuardGuard],
    loadChildren: () =>
      import("./cooldash/cooldash.module").then(m => m.CoolDashModule)
  },
  {
    path: "login",
    loadChildren: () => import("./login/login.module").then(m => m.LoginModule)
  },
  {
    path: "forgotpassword",
    component:ForgotpasswordComponent
  },
  {
    path: "verification",
    component:ForgotpasswordComponent
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent
  },
  {
    path: "signup",
    component: SignupComponent
  },
  {
    path: "verify",
    component: VerifyComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
